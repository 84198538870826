import "./app.css";

function App() {
  return (
    <div class="container-fluid">
      <div class="container prompt">
        <div class="row pt-c">
          <div class="col">
            <img src="assets/images/khanki.svg" alt="" width="35%" />
          </div>
        </div>
        <div class="row pt-4">
          <p>
            Ensure inclusive and equitable quality education and promote
            lifelong learning <b>(SDG 4)</b>
          </p>
        </div>
        <div class="row pt-4">
          <div class="col">
            <p>
              <b>-</b> scholar@khanki.org
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
